import React, {useState, useContext, useEffect} from 'react'
import PiChart from 'react-apexcharts'
import reportContext from '../../context/report/reportContext';

function Pichart({ date }) {
    const contextReport = useContext(reportContext);
  const { typereport, getExpensebytype } = contextReport

  useEffect(() => {
    
    if (date.fromDate && date.toDate) {
        getExpensebytype(date.fromDate, date.toDate);

    }
    
      // eslint-disable-next-line
    }, [date]);

    useEffect(() => {
        const data = typereport
        const type_ar = data.map(item => item.type);
        const amounts_ar = data.map(item => parseFloat(item.Amount));
        
        setOption(prevOption => ({
          ...prevOption, // Keep the rest of the chart options
          labels: type_ar
        }));
  
        setSeries(amounts_ar)
      
        
      }, [typereport])

    const [option, setOption] = useState({
        chart: {
          width: 380,
          type: 'pie',
          animations: {
            enabled: true, 
            easing: 'easeinout', 
            speed: 1500, 
            animateGradually: {
              enabled: true,
              delay: 200, 
            },
            dynamicAnimation: {
              enabled: true,
              speed: 1000, 
            },
          },
        },
        labels: [],
        fill: {
            type: "gradient",
            gradient: {
              shade: "dark",
              type: "horizontal",
              shadeIntensity: 0.5,
              gradientToColors: ['#FAD961', '#F76B1C'],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100]
            }
          },
          legend: {
            show: true, // Show the legend
            position: 'right', // Can be 'top', 'right', 'bottom', or 'left'
            horizontalAlign: 'center', // Align the legend horizontally
            verticalAlign: 'middle', // Align the legend vertically (for top/bottom positions)
            fontSize: '14px', // Set font size for legend labels
            labels: {
              useSeriesColors: true, // Whether to use the series' colors for the labels
            },
            markers: {
              width: 12, // Width of the color box next to the label
              height: 12, // Height of the color box next to the label
              radius: 12, // Border radius for rounded markers
              strokeWidth: 0, // Border width around the markers
            },
            itemMargin: {
              horizontal: 10, // Space between legend items horizontally
              vertical: 5, // Space between legend items vertically
            },
          },
          dataLabels: {
            enabled: true, // Enable data labels
            style: {
              fontSize: '20px', // Increase font size of data labels
              fontFamily: 'Helvetica, Arial, sans-serif', // Font family for data labels
            //   fontWeight: 'bold',
              colors: ['#fff'], // Set color of the data labels
            }
          },
          stroke: {
            show: true, // Disable the stroke (border)
            width: 1, // Set border width to 0
            colors: ["#1B213B"], // Set the stroke color to transparent
          },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            }
          }
        }]
        }
    )

    const [series, setSeries] = useState([])


  return (
    <React.Fragment>
    <PiChart
      options={option}
      series={series}
      type="pie"
      height="350"
      width="100%"
      />
      </React.Fragment>
  )
}

export default Pichart
