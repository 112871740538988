import React, { useContext, useState, useEffect} from 'react';
import incomecontext from "../context/income/incomeContext"

function Addincome() {
    const context = useContext(incomecontext);
    const {addIncome, incometypes, fetchIncomeType} = context;

    const [income, setIncome] = useState({type: "Salary", amount: "", date: new Date().toISOString().split('T')[0]})
    

    useEffect(() => {
        if (localStorage.getItem('token')) {
            fetchIncomeType();
            }
          // eslint-disable-next-line
        }, []);

    const handleClick = (e) => {
        e.preventDefault();
        addIncome(income.type, income.amount, income.date)
        console.log(income)
        setIncome({type: "Salary", amount: "", date: new Date().toISOString().split('T')[0]})

    }

    const onChange = (e) => {
        setIncome({...income,[e.target.name]: e.target.value})
    }



  return (
    <>
    <div className='title'>
            <h2>Add a Income</h2>
    </div>
        <form className='my-3'>
        <div className='mb-3'>
        <label>Type</label>
        <select  name= "type" value={income.type} onChange={onChange}>
          <option value="">Select a category</option>
          {incometypes.map((category) => (
            <option key={category.id} value={category.type}>
              {category.type}
            </option>
          ))}
        </select>
      </div>

            <div className="mb-3">
                <label htmlFor="amount" className="form-label">Amount</label>
                <input type="number" className="form-control" id="amount" name="amount" aria-describedby="emailHelp" value={income.amount} onChange={onChange}/>
            </div>

            <div className="mb-3">
                    <label htmlFor="date" className="form-label">Date</label>
                    <input type="date" className="form-control" id="date" name="date" aria-describedby="emailHelp" value={income.date} onChange={onChange}/>
            </div>
            <button disabled={!income.amount.length} type="submit" className="btn btn-primary" onClick={handleClick}>Add Income</button>
            
     
      </form>
      </>
  )
}

export default Addincome
