import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import { Navbar } from './components/Navbar';
import { Home } from './components/Home';
import { About } from './components/About';
import ExpenseState from './context/expense/expenseState';
import Alert from './components/Alert';
import Login from './components/Login';
import Signup from './components/Signup';
import { useState } from 'react';
import Report from './components/Report'
import Dashboard from './components/Dashboard'
import MasterData from './components/MasterData';
import Category from './components/page/Category';
import Subcategory from './components/page/Subcategory';
import ReportState from './context/report/reportState';
import IncomeState from './context/income/incomeState';
import Incometype from './components/page/incometype';

function App() {
  const [alert, setAlert] = useState(null);
  const showAlert = (message, type) => {
    setAlert({
      msg: message,
      type: type
    })
    setTimeout(() => {
      setAlert(null)
    }, 2000);
  }

  return (
    <>
    <ExpenseState>
      <ReportState>
        <IncomeState>
          <Router>
            <Navbar />
            <Alert alert={alert}/>
            <div>
            <Routes>
              <Route exact path="/" element={<Home showAlert={showAlert}/>} />
              <Route exact path="/reports" element={<Report showAlert={showAlert}/>} />
              <Route exact path="/dashboard" element={<Dashboard/>} />
              <Route exact path="/masterdata" element={<MasterData/>}>
                <Route path="category" element={<Category/>}/>
                <Route path="subcategory" element={<Subcategory/>}/>
                <Route path="incometype" element={<Incometype/>}/>
              </Route>
              <Route exact path="/about" element={<About/>} />
              <Route exact path="/login" element={<Login showAlert={showAlert}/>} />
              <Route exact path="/signup" element={<Signup showAlert={showAlert}/>} />
            </Routes>
            </div>
          </Router>
       </IncomeState>
      </ReportState>
    </ExpenseState>
    </>
  );
}

export default App;
