import React, {useState, useContext, useEffect} from 'react'
import BarChart from 'react-apexcharts'
import reportContext from '../../context/report/reportContext';
import expensecontext from "../../context/expense/expenseContext"


function Subcatbarchart({ date }) {
    const context = useContext(expensecontext);
    const {categories, fetchCategories} = context;
    const contextReport = useContext(reportContext);
    const { subCatreport, getsubCatexpense } = contextReport

    const [category, setCategory] = useState("")
    const [functionRun, setFunctionRun] = useState(false)

    useEffect(() => {
        const data = subCatreport
        const categories_ar = data.map(item => item.sub_category);
        const amounts_ar = data.map(item => item.Amount);
  
        setOption(prevOption => ({
          ...prevOption, // Keep the rest of the chart options
          xaxis: {
            ...prevOption.xaxis, // Keep other xaxis options
            categories: categories_ar // Update the categories with new data
          }
        }));
  
        setSeries(prevSeries => [{
          ...prevSeries[0],  // Spread the previous series object to retain other fields (like name)
          data: amounts_ar      // Update only the data field
        }]);
      
        
      }, [subCatreport])
   
    const onClick= ()=> {
        if(!functionRun)
        fetchCategories();
        setFunctionRun(true);
    }

    const onChange = (e)=>{
        setCategory(e.target.value)
        getsubCatexpense(date.fromDate, date.toDate, e.target.value)
    }

    const [option, setOption] = useState({
        chart: {
        foreColor: "#fff",
        height:450,
        type: 'bar',
        animations: {
          enabled: true,    // Enable animations
          easing: 'easeinout',  // Easing function for smoothness
          speed: 800,       // Duration of animation in milliseconds
          animateGradually: {
            enabled: true,
            delay: 150      // Gradual delay between data points
          },
          dynamicAnimation: {
            enabled: true,
            speed: 150      // Speed of dynamic animations on updates
          }
        },
      },
      colors: ["#FCCF31", "#17ead9", "#f02fc2"],
      stroke: {
        width: 3
      },
      xaxis:{
        categories:[]
    },
      plotOptions: {
        bar: {
          borderRadius: 4,
          borderRadiusApplication: 'end',
          horizontal: false,
        }
      },
      dataLabels: {
        enabled: false
      },
      grid: {
        borderColor: "#40475D"
      },
      fill: {
        type: "gradient",
        gradient: {
          gradientToColors: ["#F55555", "#6078ea", "#6094ea"]
        }
      },
      tooltip: {
        theme: "dark",
      },
      xaxis: {
        axisTicks: {
          color: "#333"
        },
        axisBorder: {
          color: "#333"
        }
      },
      title:{
        text:"Subcategory Wise Expense",
        align:"center",
        margin:20,
        offsetY:20,
        style:{
            fontSize: '15px'
        }
      }})

    const [series, setSeries] = useState([{
        name: "INR",
        data:[]
    }])

  return (
    <div>
      <div className='mb-3'>
        <select  name= "category" value={category} onClick={onClick} onChange={onChange} >
          <option value="">Select a category</option>
          {categories.map((category) => (
            <option key={category.id} value={category.type}>
              {category.type}
            </option>
          ))}
        </select>
      </div>
      <React.Fragment>
    <BarChart
      options={option}
      series={series}
      type="bar"
      height="350"
      width="100%"
      />
      </React.Fragment>
    </div>
  )
}

export default Subcatbarchart
