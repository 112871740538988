import React, {useContext, useEffect, useState} from 'react'
import './Overview.css'
import reportContext from '../context/report/reportContext';
const moment = require('moment-timezone');

function Overview() {
    const contextReport = useContext(reportContext);
    const { lastTen, getLastTenExpenses, getTotalExpensebyDate, getTotalIncomebyDate } = contextReport
    const [totalexpense, setTotalexpense] = useState("")
    const [totalincome, setTotalincome] = useState("")
    const [balance, setBalance] = useState("")

    useEffect(() => {
        if (localStorage.getItem('token')) {
        const Date =  getCurrentMonthStartAndEnd();
        const fetchTotalExpense = async () => {
            const totalExp = await getTotalExpensebyDate(Date.startOfMonth, Date.currentOfMonth);
            setTotalexpense(totalExp.Amount);
            
        };

        const fetchTotalincome = async () => {
            const totalIncome = await getTotalIncomebyDate(Date.startOfMonth, Date.currentOfMonth);
            setTotalincome(totalIncome.totalIncome); 
        };
        fetchTotalExpense();
        fetchTotalincome();
        
        getLastTenExpenses();
    }
        //eslint-disable-next-line
    
    }, [])

    useEffect(() => {
        if (localStorage.getItem('token')) {
        setBalance(totalincome - totalexpense);
        }
    }, [totalincome, totalexpense]);

    function getCurrentMonthStartAndEnd() {
        const startOfMonth = moment.tz("Asia/Kolkata").startOf('month').format('YYYY-MM-DD');
        const currentOfMonth = moment.tz("Asia/Kolkata").format('YYYY-MM-DD');
        
        return { startOfMonth, currentOfMonth };
      }

  return (
        <div className='card-container'>
            <h3 className='main-title'>Current Month Summary</h3>
            <div className='card-wrapper'>
                <div className='payment-card light-red'>
                    <div className='card-header'>
                        <div className='amount'>
                        <span className='card-title'>
                            Total Income  
                            </span>
                            <span className='amount-value'>
                                {totalincome}
                            </span>
                    </div>
                    </div>
                </div>

                <div className='payment-card light-purple'>
                    <div className='card-header'>
                        <div className='amount'>
                        <span className='card-title'>
                            Used Amount  
                            </span>
                            <span className='amount-value'>
                            {totalexpense}
                            </span>
                    </div>
                    </div>
                </div>

                <div className='payment-card light-green'>
                    <div className='card-header'>
                        <div className='amount'>
                        <span className='card-title'>
                            Balance Amount 
                            </span>
                            <span className='amount-value'>
                                {balance}
                            </span>
                    </div>
                    </div>
                </div>
            </div>
            <div className='table-card'>
              <h3 className='table-title'>Last 10 Expenses</h3>
            
    
                <div class="table-container">
        <table class="styled-table">
            <thead>
                <tr>
                    <th>Date</th>
                    <th>Category</th>
                    <th>Sub-Category</th>
                    <th>Type</th>
                    <th>Amount</th>
                    
                </tr>
            </thead>
            <tbody>
            {lastTen.length === 0 && 'No Expenses to display'}
            {lastTen.map((expense, index) => (
                <tr>
                    <td>{expense.date.split("T")[0]}</td>
                    <td>{expense.category}</td>
                    <td>{expense.sub_category}</td>
                    <td>{expense.type}</td>
                    <td>{expense.amount}</td>
                </tr>
            ))}
                
            </tbody>
        </table>
    
                
              </div>  
            </div>
        </div>

  )
}

export default Overview


