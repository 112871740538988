import { useState } from "react"
import items from "../Data/MasterSidebar.json"
import MasterSideitem from "./MasterSideitem"
import './MasterSidebar.css'


export default function MasterSidebar(){

  const [isSidebarVisible, setSidebarVisible] = useState(true);
  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
  };

    return (
      <div className="sidebar-container">
      {/* Arrow Button to Toggle Sidebar */}
      <button className="sidebar-toggle-btn" onClick={toggleSidebar} style={{ left: isSidebarVisible ? '200px' : '0px' }}>
        {isSidebarVisible ? "◀" : "▶"}
      </button>

        {/* <div className="sidebar"> */}
        <div className={isSidebarVisible ? "sidebar open" : "sidebar closed"}>
          { items.map((item, index) => <MasterSideitem key={index} item={item} />) }
        {/* </div> */}
        </div>
        </div>
    )
}


