import Expenses from './Expenses';
import './Home.css'
import Overview from './Overview';

export const Home = (props) => {
    const {showAlert} = props;
    return (
        <div className="home-container">
            <div className="expenses-container">
                <Expenses showAlert={showAlert} />
            </div>
            <div className="overview-container">
                <Overview />
            </div>
        </div>
    )
}
