import IncomeContext from "./incomeContext";
import { useState } from "react";
import config from "../../config.json";

const IncomeState = (props) => {
    const host = config.backend_server + config.backend_port //"http://localhost:5000"
    const [incometypes, setIncometypes] = useState([])
    

    // Add Income
    const addIncome = async (type, amount, date)=>{
        //API call
            //API call
            const response = await fetch(`${host}/api/income/addincome`, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    "auth-token": localStorage.getItem('token')
                },
                body: JSON.stringify({type, amount, date})
            });
            const income = await response.json();
            console.log(income)
    }

    //Get all income type
    const fetchIncomeType = async () => {
        const response = await fetch(`${host}/api/income/fetchincometype`, {
          method: 'GET',
          headers: {
              'content-type': 'application/json',
              "auth-token": localStorage.getItem('token')
          }
      });
        const data = await response.json();
        setIncometypes(data);
      };

    //add inncome Category
   
        const addIncomeType = async (type)=>{
    //API call
        const response = await fetch(`${host}/api/income/addincometype`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                "auth-token": localStorage.getItem('token')
            },
            body: JSON.stringify({type})
        });
        const catg = await response.json();
        setIncometypes(incometypes.concat(catg))
    
}


    return (
        <IncomeContext.Provider value={{addIncome, fetchIncomeType, incometypes, addIncomeType}}>
            {props.children}
        </IncomeContext.Provider>
    )
}

export default IncomeState;