import React from 'react'


export const About = () => {

   
  return (
    <div>
      <h1>Hello</h1>
    </div>
  )
}
