import { useState } from 'react'
import reportContext from './reportContext';
import config from "../../config.json"

function ReportState(props) {
    const host = config.backend_server + config.backend_port //"http://localhost:5000"
    const [expenses, setExpenses] = useState([]);
    const [catreport, setCatreport] = useState([]);
    const [lastTen, setLastTen] = useState([]);
    const [typereport, setTypereport] = useState([]);
    const [subCatreport, setSubCatreport] = useState([]);

//Get Date Wise Expenses
const getExpensebyDate = async (from_date, to_date)=>{
    //API call
    try {
        const response = await fetch(`${host}/api/report/monthly?fromDate=${from_date}&toDate=${to_date}`, {
            method: 'GET',
            headers: {
                'content-type': 'application/json',
                "auth-token": localStorage.getItem('token')
            },
        });
        const json = await response.json()
        // console.log(json)
        setExpenses(json)
    } catch (error) {
        setExpenses([])
        
    }
}
////////////////////////////////////////////////////////////
//Get expense by category
    //API call
const getExpensebycate = async (from_date, to_date)=>{
    try {
        const response = await fetch(`${host}/api/report/category_expense?fromDate=${from_date}&toDate=${to_date}`, {
            method: 'GET',
            headers: {
                'content-type': 'application/json',
                "auth-token": localStorage.getItem('token')
            },
        });
        const json = await response.json()
        // console.log(json)
        setCatreport(json)
    } catch (error) {
        setCatreport([])
        
    }
}
////////
//Get last ten Expenses
const getLastTenExpenses = async ()=>{
    //API call
    try {
        const response = await fetch(`${host}/api/report/lasttenExpenses`, {
            method: 'GET',
            headers: {
                'content-type': 'application/json',
                "auth-token": localStorage.getItem('token')
            },
        });
        const json = await response.json()
        // console.log(json)
        setLastTen(json)
    } catch (error) {
        setExpenses([])
        
    }
}

///////
//Get total amount of Expenses by date
const getTotalExpensebyDate = async (from_date, to_date)=>{
    //API call
    try {
        const response = await fetch(`${host}/api/report/expense_total?fromDate=${from_date}&toDate=${to_date}`, {
            method: 'GET',
            headers: {
                'content-type': 'application/json',
                "auth-token": localStorage.getItem('token')
            },
        });
        const json = await response.json()
        return json
    } catch (error) {
        return null;
        
    }
}

///////
//Get total amount of Income by date
const getTotalIncomebyDate = async (from_date, to_date)=>{
    //API call
    try {
        const response = await fetch(`${host}/api/income/income_total?fromDate=${from_date}&toDate=${to_date}`, {
            method: 'GET',
            headers: {
                'content-type': 'application/json',
                "auth-token": localStorage.getItem('token')
            },
        });
        const json = await response.json()
        return json
    } catch (error) {
        return null;
        
    }
}

//Get need/want type wise expense by date 
const getExpensebytype = async (from_date, to_date)=>{
    try {
        const response = await fetch(`${host}/api/report/type_expense?fromDate=${from_date}&toDate=${to_date}`, {
            method: 'GET',
            headers: {
                'content-type': 'application/json',
                "auth-token": localStorage.getItem('token')
            },
        });
        const json = await response.json()
        // console.log(json)
        setTypereport(json)
    } catch (error) {
        setTypereport([])
        
    }
}

//Get sub category wise total expense by selecting category 

const getsubCatexpense = async (from_date, to_date, category)=>{
    try {
        const response = await fetch(`${host}/api/report/subcatWiseexpense?fromDate=${from_date}&toDate=${to_date}&category=${category}`, {
            method: 'GET',
            headers: {
                'content-type': 'application/json',
                "auth-token": localStorage.getItem('token')
            },
        });
        const json = await response.json()
        // console.log(json)
        setSubCatreport(json)
    } catch (error) {
        setSubCatreport([])
        
    }
}

  return (
    <reportContext.Provider value={{expenses, getExpensebyDate, catreport, getExpensebycate, getLastTenExpenses, lastTen, getTotalExpensebyDate, getTotalIncomebyDate, typereport, getExpensebytype, subCatreport, getsubCatexpense}}>
        {props.children}
    </reportContext.Provider>
  )
}

export default ReportState
