import React, {useState} from 'react'
import Chart from './Chart'
import Barchart from './chart/Barchart'
import './Dashboard.css'
import Pichart from './chart/Pichart'
import Subcatbarchart from './chart/Subcatbarchart'

function Dashboard() {
  const [date, setDate] = useState({fromDate: "", toDate:""})
  

  const changeDate = (e)=>{
    setDate({...date, [e.target.name]: e.target.value})
}


  return (
<>
<div className='dashboard'>
    <div className='date-range'>
    <form className="my-3">
        <div className="row">
            <div className="col-md-2 mb-3">
                <label htmlFor="fromDate" className="form-label">From Date</label>
                <input type="date" className="form-control" id="fromDate" name="fromDate" value={date.fromDate} onChange={changeDate} />
            </div>
            <div className="col-md-2 mb-3">
                <label htmlFor="toDate" className="form-label">To Date</label>
                <input type="date" className="form-control" id="toDate" name="toDate" value={date.toDate} onChange={changeDate} />
            </div>
            {/* <button type="submit" className="btn btn-primary col-md-1 my-4" >Submit</button> */}
        </div>
    </form>
</div>
<div className='box'>
      <div className='barchart'>
        <Barchart date={date}></Barchart>
      </div>
      <div className='pichart'>
        <Pichart date={date}></Pichart>
      </div>
      <div className='barchart'>
        <Subcatbarchart date={date}></Subcatbarchart>
      </div>
    </div>
    </div>
    </>
  )
}

export default Dashboard
