import React, {useState, useContext, useEffect} from 'react'
import BarChart from 'react-apexcharts'
import reportContext from '../../context/report/reportContext';



function Barchart({ date }) {
  const contextReport = useContext(reportContext);
  const { catreport, getExpensebycate } = contextReport

  useEffect(() => {
    
    if (date.fromDate && date.toDate) {
    getExpensebycate(date.fromDate, date.toDate);

    }
    
      // eslint-disable-next-line
    }, [date]);
  
    useEffect(() => {
      const data = catreport
      const categories_ar = data.map(item => item.category);
      const amounts_ar = data.map(item => item.Amount);

      setOption(prevOption => ({
        ...prevOption, // Keep the rest of the chart options
        xaxis: {
          ...prevOption.xaxis, // Keep other xaxis options
          categories: categories_ar // Update the categories with new data
        }
      }));

      setSeries(prevSeries => [{
        ...prevSeries[0],  // Spread the previous series object to retain other fields (like name)
        data: amounts_ar      // Update only the data field
      }]);
    
      
    }, [catreport])
    

    const [option, setOption] = useState({
        chart: {
        foreColor: "#fff",
        height:450,
        type: 'bar',
        animations: {
          enabled: true,    // Enable animations
          easing: 'easeinout',  // Easing function for smoothness
          speed: 800,       // Duration of animation in milliseconds
          animateGradually: {
            enabled: true,
            delay: 150      // Gradual delay between data points
          },
          dynamicAnimation: {
            enabled: true,
            speed: 150      // Speed of dynamic animations on updates
          }
        },
      },
      colors: ["#FCCF31", "#17ead9", "#f02fc2"],
      stroke: {
        width: 3
      },
      xaxis:{
        categories:[]
    },
      plotOptions: {
        bar: {
          borderRadius: 4,
          borderRadiusApplication: 'end',
          horizontal: false,
        }
      },
      dataLabels: {
        enabled: false
      },
      grid: {
        borderColor: "#40475D"
      },
      fill: {
        type: "gradient",
        gradient: {
          gradientToColors: ["#F55555", "#6078ea", "#6094ea"]
        }
      },
      tooltip: {
        theme: "dark",
      },
      xaxis: {
        axisTicks: {
          color: "#333"
        },
        axisBorder: {
          color: "#333"
        }
      },
      title:{
        text:"Expense Overview",
        align:"center",
        margin:20,
        offsetY:20,
        style:{
            fontSize: '15px'
        }
      }})

    const [series, setSeries] = useState([{
        name: "INR",
        data:[]
    }])


  //  const onClick = () => {
  //   console.log(catreport);
  //       setOption(prevOptions => ({
  //           ...prevOptions,
  //           plotOptions: {
  //             ...prevOptions.plotOptions,
  //             bar: {
  //               ...prevOptions.plotOptions.bar,
  //               horizontal: !prevOptions.plotOptions.bar.horizontal
  //             }
  //           }
  //         }));
  //   }

    // const onClick1 = () => {
    //   // console.log(catreport)
    //   const data = catreport
    //   const categories_ar = data.map(item => item.category);
    //   const amounts_ar = data.map(item => item.Amount);

    //   setOption(prevOption => ({
    //     ...prevOption, // Keep the rest of the chart options
    //     xaxis: {
    //       ...prevOption.xaxis, // Keep other xaxis options
    //       categories: categories_ar // Update the categories with new data
    //     }
    //   }));

    //   setSeries(prevSeries => [{
    //     ...prevSeries[0],  // Spread the previous series object to retain other fields (like name)
    //     data: amounts_ar      // Update only the data field
    //   }]);
       
  
    //   }



  return (
    <React.Fragment>
    <BarChart
      options={option}
      series={series}
      type="bar"
      height="350"
      width="100%"
      />
      </React.Fragment>
      
  )
}

export default Barchart
