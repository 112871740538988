import React from 'react'
import MasterSidebar from './MasterSidebar'
import './MasterData.css'
import { Outlet } from 'react-router-dom'


function MasterData() {
  return (

    <>
    <div>
    <MasterSidebar/>
    <Outlet />
    </div>
    </>
  )
}

export default MasterData


