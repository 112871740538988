import ExpenseContext from "./expenseContext";
import { useState } from "react";
import config from "../../config.json"

const ExpenseState = (props) => {
    const host = config.backend_server + config.backend_port //"http://localhost:5000"
    const expensesInitial = []
    const [expenses, setExpenses] = useState(expensesInitial)
    const [categories, setCategories] = useState([]);
    const [subcategories, setSubcategories] = useState([]);
    

//Get All Expenses
const getExpense = async ()=>{
        //API call
        const response = await fetch(`${host}/api/expense/fetchallexpenses`, {
            method: 'GET',
            headers: {
                'content-type': 'application/json',
                "auth-token": localStorage.getItem('token')
            }
        });
        const json = await response.json()
        // console.log(json)
        setExpenses(json)
    }


//Add a Expense
const addExpense = async (description, amount, date, category, sub_category, type)=>{
    //API call
        //API call
        const response = await fetch(`${host}/api/expense/addexpense`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                "auth-token": localStorage.getItem('token')
            },
            body: JSON.stringify({description, amount, date, category, sub_category, type})
        });
        const expense = await response.json();
        setExpenses(expenses.concat(expense))
}

// Delete a Expense
// as of now not requrie.

// Edit a Expense
const editExpense = async (id, description, amount, date, category, sub_category, type)=>{
    //API call
    const response = await fetch(`${host}/api/expense/updateexpense/${id}`, {
        method: 'PUT',
        headers: {
            'content-type': 'application/json',
            "auth-token": localStorage.getItem('token')
        },
        body: JSON.stringify({description, amount, date, category, sub_category, type})
    });
    const json =  await response.json();
    console.log(json)
    
    let newExpense = JSON.parse(JSON.stringify(expenses))
    //Logic to edit in client
    for (let index = 0; index < newExpense.length; index++) {
        const element = newExpense[index];
        if(element.id === id){
            newExpense[index].category = category;
            newExpense[index].sub_category = sub_category;
            newExpense[index].description = description;
            newExpense[index].amount = amount;
            newExpense[index].type = type;
            newExpense[index].date = date
            break;
        }
        
    }
    setExpenses(newExpense);
}

//Get all Category
const fetchCategories = async () => {
    const response = await fetch(`${host}/api/category/fetchcategory`, {
      method: 'GET',
      headers: {
          'content-type': 'application/json',
          "auth-token": localStorage.getItem('token')
      }
  });
    const data = await response.json();
    setCategories(data);
  };

  //Get Subcategory
  const fetchSubCat = async (categoryId) => {
  const response = await fetch(`${host}/api/category/fetchsubcat/${categoryId}`, {
    method: 'GET',
    headers: {
        'content-type': 'application/json'
    }
});
  const data = await response.json();
  setSubcategories(data);
};

//add Category
const addCategory = async (category)=>{
    //API call
        //API call
        const response = await fetch(`${host}/api/category/addcategory`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                "auth-token": localStorage.getItem('token')
            },
            body: JSON.stringify({category})
        });
        const catg = await response.json();
        setCategories(categories.concat(catg))
}

//add sub Category
const addsubCategory = async (categoryId, sub_category)=>{
    //API call
        //API call
        const response = await fetch(`${host}/api/category/addsub_category/${categoryId}`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({sub_category})
        });
        const subCatg = await response.json();
        setSubcategories(subcategories.concat(subCatg))
}



    return (
        <ExpenseContext.Provider value={{expenses, addExpense, editExpense, getExpense, categories, fetchCategories, subcategories, fetchSubCat, addCategory, addsubCategory}}>
            {props.children}
        </ExpenseContext.Provider>
    )

}


export default ExpenseState;
