import React, { useContext, useEffect, useRef, useState } from 'react'
import expensecontext from "../context/expense/expenseContext"
import { useNavigate} from 'react-router-dom'
import reportContext from '../context/report/reportContext';
import Spinner from './spinner';
import * as XLSX from 'xlsx';

function Report(props) {
    const context = useContext(expensecontext);
    const { editExpense, categories, fetchCategories, subcategories, fetchSubCat } = context;
    const contextReport = useContext(reportContext);
    const { expenses, getExpensebyDate } = contextReport
    let navigate = useNavigate();

    useEffect(() => {
        if (localStorage.getItem('token')) {
        // getExpense();
        }
        else{
            navigate("/login")
        }
        //eslint-disable-next-line
    }, [])

    const ref = useRef(null)
    const refClose = useRef(null)
    const [expense, setExpense] = useState({id: "", edescription: "", eamount: "", edate: "", ecategory: "", esub_category: "", etype: ""})
    const [date, setDate] = useState({fromDate: "", toDate:""})
    const [loading, setLoading] = useState(false)

    const updateNote = (currentExpense) => {
        ref.current.click();
        fetchCategories();
        setExpense({id: currentExpense.id, edescription: currentExpense.description, eamount: currentExpense.amount, edate: currentExpense.date.split("T")[0], ecategory: currentExpense.category, esub_category: currentExpense.sub_category, etype: currentExpense.type})
    }

    const handleClick = (e)=>{
        // console.log("Updating the expense..", expense)
        editExpense(expense.id, expense.edescription, expense.eamount, expense.edate, expense.ecategory, expense.esub_category, expense.etype)
        refClose.current.click();
        props.showAlert("Updated Successfully", "success")
        
    }

    const onChange = (e)=>{
        setExpense({...expense, [e.target.name]: e.target.value})
        if (e.target.name === "ecategory") {
            let cat = categories.find(o => o.type === e.target.value)
            console.log(cat.id);
            // fetch subcategory as per category
            fetchSubCat(cat.id);
            if (e.target.name === "esub_category") {
              setExpense({...expense, [e.target.name]: e.target.value})
            }
          }
        }

    const fetchData = async (e)=>{
        e.preventDefault();
        setLoading(true)
        await getExpensebyDate(date.fromDate, date.toDate)
        setLoading(false)
    }

    const changeDate = (e)=>{
        setDate({...date, [e.target.name]: e.target.value})
    }

    const downloadExcel = async () => {
        // Create a new workbook and add a worksheet
        await getExpensebyDate(date.fromDate, date.toDate)
        const wb =  XLSX.utils.book_new();
        
        // Create worksheet from the data
        const ws = XLSX.utils.json_to_sheet(
           await expenses.map((expense, index) => ({
                '#': index + 1,
                Date: expense.date.split("T")[0],
                Category: expense.category,
                'Sub-category': expense.sub_category,
                Amount: expense.amount,
                Type: expense.type,
                Description: expense.description
            }))
        );
    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, 'Expenses');

    // Generate and download Excel file
    XLSX.writeFile(wb, 'expense_report.xlsx');
  };

    return (
        <>
        <div>
    <form className="my-3">
        <div className="row">
            <div className="col-md-2 mb-3">
                <label htmlFor="fromDate" className="form-label">From Date</label>
                <input type="date" className="form-control" id="fromDate" name="fromDate" value={date.fromDate} onChange={changeDate} />
            </div>
            <div className="col-md-2 mb-3">
                <label htmlFor="toDate" className="form-label">To Date</label>
                <input type="date" className="form-control" id="toDate" name="toDate" value={date.toDate} onChange={changeDate} />
            </div>
            <button type="submit" className="btn btn-primary col-md-1 my-4" onClick={fetchData}>Submit</button>
        </div>
    </form>
</div>


            <button  ref={ref} type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#exampleModal">
                Launch demo modal
            </button>
            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Edit Expense</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form className='my-3'>

                            <div className='mb-3'>
                                <label>Category:</label>
                                  <select  name= "ecategory" value={expense.ecategory} onChange={onChange}>
                                  <option value="">Select a category</option>
                                 {categories.map((category) => (
                                 <option key={category.id} value={category.type}>
                                   {category.type}
                                    </option>
                                ))}
                                </select>
                            </div>

                            <div className='mb-3'>
                                <label>Subcategory:</label>
                                <select name = "esub_category" value={expense.esub_category} onChange={onChange}>
                                <option value="">Select a subcategory</option>
                                {subcategories.map((subcategory) => (
                                    <option key={subcategory.id} value={subcategory.type}>
                                    {subcategory.type}
                                    </option>
                                ))}
                                </select>
                            </div>

                                {/* <div className="mb-3">
                                    <label htmlFor="category" className="form-label">Category</label>
                                    <input type="text" className="form-control" id="ecategory" name="ecategory" value={expense.ecategory} aria-describedby="emailHelp" onChange={onChange} />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="sub_category" className="form-label">Sub Category</label>
                                    <input type="text" className="form-control" id="esub_category" name="esub_category" value={expense.esub_category} aria-describedby="emailHelp" onChange={onChange} />
                                </div> */}
                                <div className="mb-3">
                                    <label htmlFor="amount" className="form-label">Amount</label>
                                    <input type="number" className="form-control" id="eamount" name="eamount" value={expense.eamount} aria-describedby="emailHelp" onChange={onChange} />
                                </div>

                                <div className='mb-3'>
                                    <label>Type</label>
                                    <select name = "etype" value={expense.etype} onChange={onChange}>
                                    {/* <option value="">Select a Type</option> */}
                                    <option value="Need">Need</option>
                                    <option value="Want">Want</option>
                                    </select>
                                 </div>


                                {/* <div className="mb-3">
                                    <label htmlFor="type" className="form-label">Type</label>
                                    <input type="text" className="form-control" id="etype" name="etype" value={expense.etype} aria-describedby="emailHelp" onChange={onChange} />
                                </div> */}
                                <div className="mb-3">
                                    <label htmlFor="description" className="form-label">Description</label>
                                    <input type="text" className="form-control" id="edescription" name="edescription" value={expense.edescription} aria-describedby="emailHelp" onChange={onChange} />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="date" className="form-label">Date</label>
                                    <input type="date" className="form-control" id="edate" name="edate" value={expense.edate} aria-describedby="emailHelp" onChange={onChange} />
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button ref={refClose} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button disabled={!expense.eamount.length} onClick={handleClick} type="button" className="btn btn-primary">Update</button>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                
      {/* Excel Export Button */}
      <button className="btn btn-success mb-3" onClick={downloadExcel}>Export to Excel</button>

                <div className="container my-3" style={{overflowY:'auto'}}>
                    <h2>Your Expense</h2>
                    
                    <table className="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Date</th>
                                <th scope="col">Category</th>
                                <th scope="col">Sub-category</th>
                                <th scope="col">Amount</th>
                                <th scope="col">Type</th>
                                <th scope="col">Description</th>
                                <th className='text-center' scope="col">Action</th>
                            </tr>
                        </thead>
                        {loading && <Spinner/>}
                        <tbody>
                        {expenses.length === 0 && 'No Expenses to display'}
                            {expenses.map((expense, index) => (
                                <tr>
                                    <th scope="row">{index + 1}</th>
                                    <td >{expense.date.split("T")[0]}</td>
                                    <td >{expense.category}</td>
                                    <td>{expense.sub_category}</td>
                                    <td>{expense.amount}</td>
                                    <td>{expense.type}</td>
                                    <td>{expense.description}</td>
                                    <td  className='text-center'><i className="fa-regular fa-pen-to-square" onClick={()=>{updateNote(expense)}}></i></td>
                                </tr >
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default Report
