import React, {useContext, useState, useEffect, useRef } from 'react'
import expensecontext from "../../context/expense/expenseContext"
import './Subcategory.css'

function Subcategory() {
    const context = useContext(expensecontext);
    const {categories, fetchCategories, subcategories, fetchSubCat, addsubCategory} = context;
    const [category, setCategory] = useState("")
    const [selectedCategoryid, setSelectedCategoryid] = useState('');
    const [subcategory, setSubcategory] = useState("")

    const ref = useRef(null)
    const refClose = useRef(null)


    useEffect(() => {
          fetchCategories();
          // eslint-disable-next-line
        }, []);

    const handleClick = (e)=>{
        addsubCategory(selectedCategoryid, subcategory)
        refClose.current.click();
        }

    const onChange = (e)=>{
        
        if (e.target.name === "category") {
            setCategory(e.target.value)
            let cat = categories.find(o => o.type === e.target.value)
            setSelectedCategoryid(cat.id)

            fetchSubCat(cat.id);
        }
        if(e.target.name === "sub_category"){
            setSubcategory(e.target.value)

        }

    }



  return (
    <>
    <div className="container">
        <div className='sub-container'>
    <div className='category'> 
            <form className='my-3'>
            <div className='mb-3'>
        <label>Select Category</label>
        <select  name= "category" value={category} onChange={onChange}>
          <option value="">Select a category</option>
          {categories.map((category) => (
            <option key={category.id} value={category.type}>
              {category.type}
            </option>
          ))}
        </select>
      </div>
      </form>
      </div>
          
        <div className='master-table'>
        <table className="table table-striped table-hover">
        <thead>
            <tr>
            <th scope="col">No</th>
            <th scope="col">Type</th>
            </tr>
        </thead>
        <tbody>
            {subcategories.length === 0 && <tr><td colSpan="2">No Category to display</td></tr>}
            {subcategories.map((category, index) => (
            <tr key={index}>
                <th scope="row">{index + 1}</th>
                <td>{category.type}</td>
            </tr>
            ))}
        </tbody>
        </table>
        </div>
        </div>
        
        <div className='title-bar'>
            <div className="header">
            <h3>Add a Sub Category</h3>
            </div>
        <button disabled={!selectedCategoryid} ref={ref} type="button" className='add-button' data-bs-toggle="modal" data-bs-target="#exampleModal">
            Add New
        </button>
        <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">Add New</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <form className='my-3'>
                       
                             <div className="mb-3">
                                <label htmlFor="category" className="form-label">Sub Category</label>
                                <input type="text" className="form-control" id="subcategory" name="sub_category" value={subcategory} aria-describedby="emailHelp" onChange={onChange} />
                            </div>
                            
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button ref={refClose} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button onClick={handleClick} type="button" className="btn btn-primary">Save</button>
                    </div>
                </div>
            </div>
        </div>
        </div>
        </div>
        </>
  )
}

export default Subcategory
