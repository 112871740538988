import React, { Component } from 'react'
import BarChart from 'react-apexcharts'

export default class Chart extends Component {
    constructor(props){
        super(props);
        this.state={
            options:{
                chart: {
                    height:450,
                    type: 'bar'
                  },
                  xaxis:{
                    categories:["Grocery", "Fruits", "vegitables", "Petrol", "Food", "Medicine", "Clothes", "Bills"]
                },
                  plotOptions: {
                    bar: {
                      borderRadius: 4,
                      borderRadiusApplication: 'end',
                      horizontal: false,
                    }
                  },
                  dataLabels: {
                    enabled: false
                  },
                  title:{
                    text:"Overview of Expense",
                    align:"center",
                    margin:20,
                    offsetY:20,
                    style:{
                        fontSize: '15px'
                    }
                  }
            },
            series:[{
                name: "INR",
                data:[5000,1500,2500,4500,3600,1456,4587,4598]
            }],

        }
    }

    onClick = () => {
        console.log("sjdhasdhkjah")
        this.setState({
            option: {
                ...this.state.options,
                plotOptions:{
                    ...this.state.options.plotOptions,
                    bar:{
                        ...this.state.options.plotOptions.bar,
                        horizontal: false
                }
            }
        }})
    }

  render() {
    return (
    <React.Fragment>
    <BarChart
      options={this.state.options}
      series={this.state.series}
      type="bar"
      height="350"
      // width="35%"
      />
      <button onClick={this.onClick}>Change</button>
      </React.Fragment>
    )
  }
}
