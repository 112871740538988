import React, { useEffect, useContext, useRef, useState } from 'react'
import expensecontext from "../../context/expense/expenseContext"
import './Category.css'

function Category() {
  const context = useContext(expensecontext);
  const {categories, fetchCategories, addCategory} = context;
  const [category, setCategory] = useState({category:""})


  const ref = useRef(null)
  const refClose = useRef(null)

  useEffect(() => {
    fetchCategories();
    // eslint-disable-next-line
  }, [])

  const handleClick = (e)=>{
    addCategory(category.category)
    refClose.current.click();
  }

  const onChange = (e)=>{
    setCategory({...category, [e.target.name]: e.target.value})
  }


  return (
    <>
    <div className="container">
    
    <div className="scrollable-div">
    <div className='master-table'>
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              <th scope="col">No</th>
              <th scope="col">Type</th>
            </tr>
          </thead>
          <tbody>
            {categories.length === 0 && <tr><td colSpan="2">No Category to display</td></tr>}
            {categories.map((category, index) => (
              <tr key={index}>
                <th scope="row">{index + 1}</th>
                <td>{category.type}</td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
    </div>

 <div className='title-bar'>
 <div className="header">
    <h3>Expense Category</h3>
  </div>

<button  ref={ref} type="button" className='add-button' data-bs-toggle="modal" data-bs-target="#exampleModal">
            Add New
        </button>
        <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">Add New</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <form className='my-3'>
                       
                             <div className="mb-3">
                                <label htmlFor="category" className="form-label">Category</label>
                                <input type="text" className="form-control" id="category" name="category" value={category.category} aria-describedby="emailHelp" onChange={onChange} />
                            </div>
                            
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button ref={refClose} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button disabled={!category.category.length} onClick={handleClick} type="button" className="btn btn-primary">Save</button>
                    </div>
                </div>
            </div>
        </div>
        </div>
        </div>  
        
        
        {/* </div> */}
  </>
  )
}

export default Category
