import React, {useState} from 'react'
import { useNavigate} from 'react-router-dom'
import config from "../config.json"

const host = config.backend_server
const port = config.backend_port

function Signup(props) {
  const [credentials, setCredentials] = useState({first_name: "", last_name: "", user_name: "", contact_no: "", email: "", pass: ""})
  let navigate = useNavigate();

  const handleSubmit = async (e)=>{
      e.preventDefault();
      const {first_name, last_name, user_name, contact_no, email, pass} = credentials;
      const response = await fetch(`${host}${port}/api/auth/createuser`, {
          method: 'POST',
          headers: {
              'content-type': 'application/json'
          },
          body: JSON.stringify({first_name, last_name, user_name, contact_no, email, pass})
      });
      const json = await response.json()
      console.log(json);
      if(json.success){
          //redirect to login page
          navigate("/login");
          props.showAlert("Account Created Successfully","success")

      }
      else{
          props.showAlert("Invalid Details","danger")
      }
  }

  const onChange = (e)=>{
      setCredentials({...credentials, [e.target.name]: e.target.value})
  }

  return (
    <div className='container mt-3'>
        <h2>Create An Account To Use Expense Book</h2>
            <form onSubmit={handleSubmit}>
            <div className="mb-3">
                    <label htmlFor="first_name" className="form-label">First Name</label>
                    <input type="first_name" className="form-control" value={credentials.first_name} onChange={onChange} name="first_name" id="first_name" required/>
                </div>
                <div className="mb-3">
                    <label htmlFor="last_name" className="form-label">Last Name</label>
                    <input type="last_name" className="form-control" value={credentials.last_name} onChange={onChange} name="last_name" id="last_name" required/>
                </div>
                <div className="mb-3">
                    <label htmlFor="user_name" className="form-label">User Name</label>
                    <input type="user_name" className="form-control" value={credentials.user_name} onChange={onChange} name="user_name" id="user_name" required/>
                </div>
                <div className="mb-3">
                    <label htmlFor="contact_no" className="form-label">Contact No</label>
                    <input type="tel" className="form-control" value={credentials.contact_no} onChange={onChange} name="contact_no" id="contact_no" required/>
                </div>
                <div className="mb-3">
                    <label htmlFor="email" className="form-label">Email address</label>
                    <input type="email" className="form-control" value={credentials.email} onChange={onChange} id="email" name="email" aria-describedby="emailHelp" required/>
                        <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div>
                </div>
                <div className="mb-3">
                    <label htmlFor="pass" className="form-label">Password</label>
                    <input type="password" className="form-control" value={credentials.pass} onChange={onChange} name="pass" id="pass" minLength={5} required/>
                </div>
                <button type="submit" className="btn btn-primary" >Submit</button>
            </form>
        </div>
  )
}

export default Signup