import React, { useEffect } from 'react'

import Addexpense from './Addexpense';
import { useNavigate} from 'react-router-dom'

const Expenses = (props) => {
    
    let navigate = useNavigate();

    useEffect(() => {
        if (localStorage.getItem('token')) {
        
        }
        else{
            navigate("/login")
        }
        //eslint-disable-next-line
    }, [])



    return (
        <>
            <Addexpense showAlert={props.showAlert}/>
           
        </>
    )
}

export default Expenses