import React, { useContext, useState, useEffect } from 'react'
import expensecontext from "../context/expense/expenseContext"
import './Addexpense.css'
import Addincome from './Addincome';

const Addexpense = (props) => {
    const context = useContext(expensecontext);
    const {addExpense, categories, fetchCategories, subcategories, fetchSubCat} = context;

    const [expense, setExpense] = useState({description: "", amount: "", date: new Date().toISOString().split('T')[0], category: "", sub_category: "", type: "Need"})
    const [selectedCategoryid, setSelectedCategoryid] = useState('');
    // State to track active tab
    const [activeTab, setActiveTab] = useState('expense'); // Default to "expense" tab

    useEffect(() => {
      if (localStorage.getItem('token')) {
        fetchCategories();
      }
        // eslint-disable-next-line
      }, []);

    const handleClick = (e)=>{
        e.preventDefault();
        addExpense(expense.description, expense.amount, expense.date, expense.category, expense.sub_category, expense.type)
        setExpense({description: "", amount: "", date: new Date().toISOString().split('T')[0], category: "", sub_category: "", type: "Need"})
        props.showAlert("Added Successfully", "success")
    }

    const onChange = (e)=>{
        setExpense({...expense, [e.target.name]: e.target.value})

        if (e.target.name === "category") {
          let cat = categories.find(o => o.type === e.target.value)
          // console.log(cat.id);
          setSelectedCategoryid(cat.id)

          // setExpense({...expense, sub_category: ""}) // Reset the subcategory when category changes
          // fetch subcategory as per category id
          fetchSubCat(cat.id);
          if (e.target.name === "sub_category") {
            setExpense({...expense, [e.target.name]: e.target.value})
          }
        }
    }

  // Function to switch between tabs
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (

    

    <div className="entry-box">

<ul className="nav nav-tabs">
        <li className="nav-item">
          <button
            className={`nav-link ${activeTab === 'expense' ? 'active' : ''}`}
            onClick={() => handleTabChange('expense')}
          >
            Add Expense
          </button>
        </li>
        <li className="nav-item">
          <button
            className={`nav-link ${activeTab === 'income' ? 'active' : ''}`}
            onClick={() => handleTabChange('income')}
          >
            Add Income
          </button>
        </li>
      </ul>

      <div className="tab-content">
        {activeTab === 'expense' && (
          <div className="tab-pane fade show active">
      <div className='title'>
            <h2>Add a Expense</h2>
            </div>
            <form className='my-3'>
            <div className='mb-3'>
        <label>Category</label>
        <select  name= "category" value={expense.category} onChange={onChange}>
          <option value="">Select a category</option>
          {categories.map((category) => (
            <option key={category.id} value={category.type}>
              {category.type}
            </option>
          ))}
        </select>
      </div>

      <div className='mb-3'>
        <label>Subcategory</label>
        <select name = "sub_category" value={expense.sub_category} onChange={onChange} disabled={!selectedCategoryid}>
          <option value="">Select a subcategory</option>
          {subcategories.map((subcategory) => (
            <option key={subcategory.id} value={subcategory.type}>
              {subcategory.type}
            </option>
          ))}
        </select>
      </div>

            
                {/* <div className="mb-3">
                    <label htmlFor="category" className="form-label">Category</label>
                    <input type="text" className="form-control" id="category" name="category" aria-describedby="emailHelp" value={expense.category} onChange={onChange}/>
                </div> */}
                {/* <div className="mb-3">
                    <label htmlFor="sub_category" className="form-label">Sub Category</label>
                    <input type="text" className="form-control" id="sub_category" name="sub_category" aria-describedby="emailHelp" value={expense.sub_category} onChange={onChange}/>
                </div> */}
                <div className="mb-3">
                    <label htmlFor="amount" className="form-label">Amount</label>
                    <input type="number" className="form-control" id="amount" name="amount" aria-describedby="emailHelp" value={expense.amount} onChange={onChange}/>
                </div>

                <div className='mb-3'>
        <label>Type</label>
        <select name = "type" value={expense.type} onChange={onChange}>
          {/* <option value="">Select a Type</option> */}
          <option value="Need">Need</option>
          <option value="Want">Want</option>
        </select>
      </div>
                {/* <div className="mb-3">
                    <label htmlFor="type" className="form-label">Type</label>
                    <input type="text" className="form-control" id="type" name="type" aria-describedby="emailHelp" value={expense.type} onChange={onChange}/>
                </div> */}
                <div className="mb-3">
                    <label htmlFor="description" className="form-label">Description</label>
                    <input type="text" className="form-control" id="description" name="description" aria-describedby="emailHelp" value={expense.description} onChange={onChange}/>
                </div>
                <div className="mb-3">
                    <label htmlFor="date" className="form-label">Date</label>
                    <input type="date" className="form-control" id="date" name="date" aria-describedby="emailHelp" value={expense.date} onChange={onChange}/>
                </div>
                
                <button disabled={!expense.amount.length} type="submit" className="btn btn-primary" onClick={handleClick}>Add Expense</button>
            </form>
            </div>
            
            
        )}

            {activeTab === 'income' && (
                <div className="tab-pane fade show active">
                  {/* Add Income Form */}
                  <Addincome/>
                  </div>
                  )}
        </div>
    </div>
  )
}

export default Addexpense