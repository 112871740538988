import React from 'react'
import loader from '../spinner.gif'

function spinner() {
  return (
    <div className='text-center'>
      <img src={loader} alt="spinner"/>
    </div>
  )
}

export default spinner
